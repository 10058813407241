<template>
<div>
    <div class="dashboard-widget-tabs" ref="top">
        <div class="dashboard-widget-tabs-content">
            <h2>
                <i class="uil uil-book-reader"></i> تحلیل‌گرها
            </h2>
            <!-- <p>خلاصه توضیحات کوتاه درباره این بخش از داشبورد شبکه اطلاع رسانی ارز طلا و سکه</p> -->
            <div class="dashboard-widget-h-btns dashboard-widget-h-btns-flex">
                <div class="switcher-btns m-left m-top">
                    <router-link :to="{ name: 'PublicTechnical'}" class="tgju-btn tgju-btn-light-orange">
                        <i class="uil uil-chart"></i> <span>تابلو تحلیل‌ها</span>
                    </router-link>
                    <router-link :to="{ name: 'PublicAnalysts'}" class="tgju-btn tgju-btn-light-orange active">
                        <i class="uil uil-book-reader"></i> <span>تحلیل‌گرها</span>
                    </router-link>
                </div>
                <a href="" @click.prevent="open_help_modal()"  class="m-left m-top tgju-btn tgju-btn-light tgju-help-btn" v-tooltip="'راهنما'"><i class="uil uil uil-question-circle"></i></a>
            </div>
        </div>
        <div class="widget-tabs is-flex public-technical-flex">
            <div class="widget-tabs-nav">
                <div :class="'tgju-btn tgju-btn-light-red m-left m-top m-bottom-mobile ' + (hot ? 'active' : '') " @click="selectHot()">
                    <i class="uil uil-fire color-red"></i> <span class="hide-m">محبوب‌ترین‌ها</span>
                </div>
                <!-- <div class="widget-select w-170 m-left m-top">
                    <v-select v-model="selected" :clearable="false" label="value" :reduce="(option) => option.key" :options="[{key: 'newest', value: 'جدیدترین'},{key: 'oldest', value: 'قدیمی‌ترین'}]" @input="selectFilter()">
                        <i class="uil uil-list-ui-alt"></i>
                    </v-select>
                </div> -->
                <div class="widget-select w-170 m-left m-top">
                    <v-select2 v-model="sortByTime" :clearable="false" label="value" :reduce="(option) => option.key" :options="[{key: 'all', value: 'بازه زمانی'},{key: 'day', value: 'روز گذشته'},{key: 'week', value: 'هفته گذشته'},{key: 'month', value: 'ماه گذشته'},{key: '3-month', value: '۳ ماه گذشته'},{key: '6-month', value: '۶ ماه گذشته'},{key: 'year', value: 'سال گذشته'}]" @input="selectFilter()">
                        <i class="uil uil-list-ui-alt"></i>
                    </v-select2>
                </div>
            </div>
            <div class="widget-tabs-btn flex-btn">
                
                <a v-if="this.$helpers.userAuth()" href="https://www.tgju.org/panel/profile" target="_blank" class="tgju-btn tgju-btn-light-orange m-right m-top m-bottom-mobile"  v-tooltip="'پنل کاربری'">
                    <i class="uil uil-user"></i>
                    <span class="hide-m" style="margin-right: 5px;">حساب کاربری</span>
                </a>
                <a v-if="!this.$helpers.userAuth()" href="https://www.tgju.org/panel/auth/login" target="_blank" class="tgju-btn tgju-btn-primary m-right m-top m-bottom-mobile"  v-tooltip="'ورود / ثبت نام'">
                    <i class="uil uil-user"></i>
                    <span class="hide-m" style="margin-right: 5px;">ورود / ثبت نام</span>
                </a>

            </div>
        </div>
    </div>
    <div class="row tgju-widgets-row dashboard-widgets-technical-list">
        <PublicAnalystsList :filter="selected" :hot="selectedHot" :time="sortByTime" :key="componentReload"></PublicAnalystsList>
    </div>
</div>
</template>

<script>
// این ویو برای تحلیل های عمومی مورد استفاده قرار میگیرد
import PublicAnalystsList from '@/components/Technical/PublicAnalystsList.vue'
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'PublicAnalysts',
    components: {
        PublicAnalystsList,
    },
    data: function () {
        return {
            showBackButton: false,
            componentReload: 0,
            selected: 'newest',
            selectedHot: 'popular',
            sortByTime: 'all',
            bookmark: false,
            hot: true,
            widget: {},
            data: {},
        }
    },
    mounted () {
        if (this.$router.history._startLocation == '/analysts'){
            this.showBackButton = true;
        }
        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    methods: {
        // این متد یک اتریبوبت را کامپوننت لیست تحلیل های عمومی تغییر میدهد جهت فیلترسازی تحلیل ها براساس
        // قدیمی ترین ، محبوب ترین ، جدیدترین
        selectFilter() {
            this.componentReload = Math.random();
        },
        // این متد یک اتریبوبت را کامپوننت مارکت تحلیل های عمومی تغییر میدهد جهت فیلترسازی تحلیل ها براساس
        selectMarket() {
            this.componentReload = Math.random();
        },
        // متد برای نمایش داغ ترین یا همان محبوب ترین تحلیل ها
        selectHot() {
            if (this.hot) {
                this.selectedHot = '';
                this.hot = false;
            } else if (!this.hot) {
                this.selectedHot = 'popular';
                this.hot = true;
            }
            this.componentReload = Math.random();
        },
        // دکمه بازگشت
        back() {
            this.$router.push({ name : 'PublicAnalysts' });
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Technical.vue',
                title: 'تحلیل‌ها',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
